import React, { Fragment } from "react";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SidebarStyles from "./SidebarStyles";
import { MuiThemeProvider } from "@material-ui/core";
import SidebarTheme from "./SidebarTheme";
// import ClientsIcon from "../Icons/ClientsIcon/ClientsIcon";
import JobsIcon from "../Icons/JobsIcon/JobsIcon";
import LocationIcon from "../Icons/LocationIcon/LocationIcon";
import { Link } from "react-router-dom";
import StaffIcon from "../Icons/StaffIcon/StaffIcon";
const options = ["Jobs", "Staff", "Location"];

const Sidebar = () => {
  const classes = SidebarStyles();

  const getOptionsIcon = option => {
    switch (option) {
      case "Jobs":
        return <JobsIcon />;
      case "Staff":
        return <StaffIcon />;
      // case "Clients":
      //   return <ClientsIcon />;
      case "Location":
        return <LocationIcon />;
      default:
        break;
    }
  };
  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={SidebarTheme}>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
          anchor="left"
        >
          <div className={classes.toolbar}>
          <Link to="/">
          <img src="/images/group-logo-ft.png" alt="" />
          </Link>
          </div>
          <List>
            {options.map((text, index) => (
              <Link to={`/${text}`} style={{ textDecoration: "none", color: "black" }} key={index}>
                <ListItem button key={text}>
                  <ListItemIcon>
                    {getOptionsIcon(text)}
                  </ListItemIcon>
                  <ListItemText primary={<div style={{fontFamily:"'Montserrat', sans-serif", fontWeight:600 }}>{text}</div>} />
                </ListItem>
              </Link>
            ))}
          </List>
        </Drawer>
      </MuiThemeProvider>
    </Fragment>
  );
};

export default Sidebar;
