import React, { Fragment } from "react";

const SectionDivider = ({ title }) => {
  return (
    <Fragment>
      <div>
        <div className="dashboard-ljc">
          <p>{title}</p>
          <div className="divider" />
        </div>
      </div>
    </Fragment>
  );
};

export default SectionDivider;
