let SessionFetches = {
  requestLogin(data) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
      method: "POST",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://getdirected.nonstopdevs.com"
      }
    })
      .then(res => {
        if(res.status == 200)
          return res.json();
        else {
          alert('Ha ocurrido un error.');
          console.log(res);
        }
      })
      .catch(error => {
        alert('Ha ocurrido un error.');
        console.log(error);
      });
  }
};

export default SessionFetches;
