export const FETCH_STAFF_LIST = "FETCH_STAFF_LIST_CLIENTS";
export const FETCH_STAFF_LIST_PENDING = "FETCH_STAFF_LIST_CLIENTS_PENDING";
export const FETCH_STAFF_LIST_FULFILLED = "FETCH_STAFF_LIST_CLIENTS_FULFILLED";
export const FETCH_STAFF_LIST_REJECTED = "FETCH_STAFF_LIST_CLIENTS_REJECTED";

export const CREATE_STAFF = "CREATE_STAFF";

export const EDIT_STAFF = "EDIT_STAFF";

export const DELETE_STAFF = "DELETE_STAFF_CLIENTS";
export const DELETE_STAFF_PENDING = "DELETE_STAFF_CLIENTS_PENDING";
export const DELETE_STAFF_FULFILLED = "DELETE_STAFF_CLIENTS_FULFILLED";
export const DELETE_STAFF_REJECTED = "DELETE_STAFF_CLIENTS_REJECTED";

export const SELECT_STAFF = "SELECT_STAFF";
