import React, { Fragment } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import NewJobStyles from "./NewJobStyles";
import { MuiThemeProvider, Button } from "@material-ui/core";
import NewJobTheme from "./NewJobTheme";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Link } from "react-router-dom";
import "./NewJob.css";

const NewJob = ({ list, staff }) => {
  const classes = NewJobStyles();

  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={NewJobTheme}>
        <div className={classes.container}>
          <Link to={`${staff ? "/Staff/new" : "/Jobs/new"}`} className={classes.link}>
            <Button className={classes.button}>
              <div className={`new-job-main-wrapper ${list && "new-job-main-wrapper-list"}`}>
                <div className={`new-job-inner-wrapper ${list && "new-job-inner-wrapper-list"}`}>
                  <AddCircleIcon className={classes.addCircle} />
                </div>
                <div className={`add-new-job ${list && "add-new-job-list"}`}>
                  <h4>{`Add a new ${staff ? "Staff" : "Job"}`}</h4>
                </div>
              </div>
            </Button>
          </Link>
        </div>
      </MuiThemeProvider>
    </Fragment>
  );
};

export default NewJob;
