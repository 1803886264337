
export const SET_ACTIVE_JOBS_LIST = "SET_ACTIVE_JOBS_LIST";
export const SET_COMPLETED_JOBS_LIST = "SET_COMPLETED_JOBS_LIST";

export const FETCH_JOBS_LIST = "FETCH_JOBS_LIST";
export const FETCH_JOBS_LIST_PENDING = "FETCH_JOBS_LIST_PENDING";
export const FETCH_JOBS_LIST_FULFILLED = "FETCH_JOBS_LIST_FULFILLED";
export const FETCH_JOBS_LIST_REJECTED = "FETCH_JOBS_LIST_REJECTED";

export const CREATE_NEW_JOB = "CREATE_NEW_JOB";
export const CREATE_NEW_JOB_PENDING = "CREATE_NEW_JOB_PENDING";
export const CREATE_NEW_JOB_FULFILLED = "CREATE_NEW_JOB_FULFILLED";
export const CREATE_NEW_JOB_REJECTED = "CREATE_NEW_JOB_REJECTED";

export const EDIT_JOB = "EDIT_JOB";

export const DELETE_JOB = "DELETE_JOB";
export const DELETE_JOB_PENDING = "DELETE_JOB_PENDING";
export const DELETE_JOB_FULFILLED = "DELETE_JOB_FULFILLED";
export const DELETE_JOB_REJECTED = "DELETE_JOB_REJECTED";

export const SELECT_JOB = "SELECT_JOB";
export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";

export const FETCH_JOB_DETAIL = "FETCH_JOB_DETAIL";
export const FETCH_JOB_DETAIL_PENDING = "FETCH_JOB_DETAIL_PENDING";
export const FETCH_JOB_DETAIL_FULFILLED = "FETCH_JOB_DETAIL_FULFILLED";
export const FETCH_JOB_DETAIL_REJECTED = "FETCH_JOB_DETAIL_REJECTED";

export const UPDATE_JOB_STAFF = "UPDATE_JOB_STAFF";