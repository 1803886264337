import React from "react";
import "./DatesRail.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IconButton } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import moment from "moment";

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        "&:hover": {
          backgroundColor: "unset !important"
        }
      }
    },
    MuiCheckbox: {
      root: {
        padding: 4
      }
    }
  }
});

const PrevArrow = props => {
  return (
    <MuiThemeProvider theme={theme}>
      <div className="dates-rails-prev-button">
        <IconButton
          style={{ cursor: "none", padding: 0, color: "#E4002B" }}
          onClick={props.onClick}
        >
          <ArrowBackIosIcon />
        </IconButton>
      </div>
    </MuiThemeProvider>
  );
};

const NextArrow = props => {
  return (
    <MuiThemeProvider theme={theme}>
      <div className="dates-rails-next-button">
        <IconButton
          style={{ cursor: "none", padding: 0, color: "#E4002B" }}
          onClick={props.onClick}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
    </MuiThemeProvider>
  );
};

const DatesRail = ({ dates = [] }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 7,
    touchMove: false,
    lazyLoad: "ondemand",
    PrevArrow: <PrevArrow />,
    NextArrow: <NextArrow />
  };

  const isToday = date => {
    var REFERENCE = moment(); // fixed just for testing, use moment();
    var TODAY = REFERENCE.clone().startOf("day");
    return moment(date).isSame(TODAY, "d");
  };

  const handleSelection = date => {
    return date.state= !date.state; 
  }

  return (
    <div className="dates-rail-wrapper">
      <Slider {...settings}>
        {dates.map((date, index) => {
          return (
            <div key={`${date}-${index}`}
              onClick={() => handleSelection(date)}
              style={date.state ? { backgroundColor: "#000" } : {}}>
              <div className="dates-date-wrapper" >
                <div>                      
                  <p style={{ fontSize: 12, color: "#535353" }}>
                    {isToday(date.date) ? "Today" : "\xa0"}
                  </p>
                  <p style={{ fontSize: 12, color: "#B3B3B3" }}>
                    {date.label}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default DatesRail;
