import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import SearchBarStyles from "./SearchBarStyles";
import { MuiThemeProvider, TextField } from "@material-ui/core";
import SearchBarTheme from "./SearchBarTheme";
import "./SearchBar.css";
import { SET_SEARCH_RESULTS } from "../../../Redux/Actions/Types/Dashboard/Jobs";

const SearchBarStaff = () => {
  const classes = SearchBarStyles();
  const dispatch = useDispatch();
  const loggedJobs = useSelector(state => state.dashboard.loggedJobs);
  const completedJobs = useSelector(state => state.dashboard.completedJobs);

  const searchTerm = term => {
    dispatch({
      type: SET_SEARCH_RESULTS,
      payload: {
        resultsLoggedJobs: loggedJobs.filter(
          lj => !lj.id.includes(term) || !lj.name.includes(term)
        ),
        resultsCompletedJobs: completedJobs.filter(
          lj => !lj.id.includes(term) || !lj.name.includes(term)
        )
      }
    });
  };

  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={SearchBarTheme}>
        <div className={classes.container} style={{ paddingTop: 66.4 }}>
          <p> Registered Staff</p>
          <div className="divider" />
          <TextField
            variant="outlined"
            className={classes.searchTextField}
            onChange={event => searchTerm(event.target.value)}
            placeholder="Search for staff"
          ></TextField>
        </div>
      </MuiThemeProvider>
    </Fragment>
  );
};

export default SearchBarStaff;
