import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import AddNewStaffStyles from "./AddNewStaffStyles";
import "./AddNewStaff.css";
import Sidebar from "../../../UI/SideBar/Sidebar";
import SectionDivider from "../../../UI/Elements/SectionDivider/SectionDivider";
import Header from "../../../UI/Header/Header";
import { Form, Field } from "react-final-form";
import { Grid, Button } from "@material-ui/core";
import DragAndDrop from "../../../UI/Elements/DragAndDrop/DragAndDrop";
import StaffActions from "../../../../Redux/Actions/dispatches/Staff";
import moment from "moment";
import PlacesAutocomplete, {geocodeByAdress, getLatLng} from "react-places-autocomplete";

const renderTF = (fieldName, title, placeholder, disabled, value) => {
  return (
    <Grid item xs={4}>
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <Field name={fieldName} defaultValue={value && value}>
        {({ input }) => (
          <input
            disabled={disabled}
            placeholder={placeholder}
            type="text"
            {...input}
            className={`add-new-job-input-tf ${disabled &&
              "job-form-disabled"}`}
          />
        )}
      </Field>
    </Grid>
  );
};

const renderCHArray = (fieldName, title, placeholder, disabled) => {
  return (
    <Grid item xs={4}>
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <div className="add-new-job-field-cb-wrapper">
        <label className="add-new-job-field-cb-label">
          <Field name={fieldName} component="input" type="radio" value={"0"}>
            {props => (
              <input
                {...props.input}
                disabled={disabled}
                className="add-new-job-field-cb"
              ></input>
            )}
          </Field>
          Yes
        </label>
        <label className="add-new-job-field-cb-label">
          <Field name={fieldName} component="input" type="radio" value={"1"}>
            {props => (
              <input
                {...props.input}
                disabled={disabled}
                className="add-new-job-field-cb"
              ></input>
            )}
          </Field>
          No
        </label>
      </div>
    </Grid>
  );
};

const renderSelect = (
  fieldName,
  title,
  placeholder,
  options,
  disabled,
  value
) => {
  return (
    <Grid item xs={4}>
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <Field name={fieldName} defaultValue={value && value}>
        {props => (
          <select
            {...props.input}
            className="add-new-job-input-select"
            disabled={disabled}
          >
            {options.map(option => {
              return <option key={`${option}`}>{option}</option>;
            })}
          </select>
        )}
      </Field>
    </Grid>
  );
};
const relationshipsOption = ["Relative", "Partner", "Friend"];

const handleSubmitButton = () => {
  document
    .getElementById("staff-form")
    .dispatchEvent(new Event("submit", { cancelable: true }));
};

const AddNewStaff = () => {
  const classes = AddNewStaffStyles();
  const today = moment().format("YYYY-MM-DD");

  const selectedStaff = useSelector(state => state.staff.selectedStaff);

  const handleStaffProcess = formObj => {
    debugger
    if (!selectedStaff) {
      formObj["time_start"] = today;
      formObj["vehicle"] = true;
      formObj["address"] = address;
      StaffActions.createStaff(formObj);
    } else {
      StaffActions.deleteStaff(selectedStaff.id);
    }
  };

  const [address, setAddress] = React.useState("");

  const handleSelect = async(value) => {
    setAddress(value);
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <Sidebar />
        <div className={classes.content}>
          <Header />
          <div style={{ paddingTop: 55 }}>
            <SectionDivider title="Add New Staff"/>
          </div>
          <div style={{ padding: " 8px 0" }}>
            <Form onSubmit={formObj => handleStaffProcess(formObj)}>
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} id="staff-form">
                  <Grid container spacing={4}>
                    {renderTF(
                      "name",
                      "Staff Name",
                      "",
                      false,
                      selectedStaff && selectedStaff.name
                    )}
                    {renderTF(
                      "email",
                      "Email",
                      "",
                      false,
                      selectedStaff && selectedStaff.email
                    )}
                    <Grid item xs={4}>
                      <div style={{ position: "relative" }}>
                        <div className="add-staff-signature-wrapper">
                          <div className="add-staff-registered-name">
                            Registered Signature
                          </div>
                          <div className="add-staff-staff-name">Staff Name</div>
                          <div className="add-staff-signature-inner-wrapper">
                            <div className="add-staff-signature-img-wrapper">
                              <img
                                className="add-staff-signature-img"
                                src="/images/signature.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="add-new-job-field-title">
                        <p>Address</p>
                      </div>
                      <PlacesAutocomplete                         
                        value={address}
                        onChange={setAddress}
                        onSelect={handleSelect}>
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading })=>(
                          <div>
                            <input 
                              className="add-new-job-input-tf address" 
                              {...getInputProps({ placeholder: "Type address"})}/>
                            <div className="address-section">
                              {loading 
                                ? <div className="address-suggestion">
                                      ...loading
                                  </div> 
                                : ''
                              }
                              {suggestions.map((suggestion) => {
                                const style={ backgroundColor: suggestion.active? '#84d1f1' : '#fff'};
                                return <div
                                          className="address-suggestion" 
                                          {...getSuggestionItemProps(suggestion, {style})}>
                                            {suggestion.description}
                                      </div>
                              })}
                            </div>
                          </div>
                          )}
                      </PlacesAutocomplete>
                    </Grid>
                    {renderTF(
                      "mobile",
                      "Mobile",
                      "",
                      false,
                      selectedStaff && selectedStaff.mobile
                    )}
                    <Grid item xs={4} />
                    {renderTF("start_date", "Start Date", "", true, today)}
                    {renderCHArray("vehicle", "Own Vehicle")}
                    <Grid item xs={4} />
                    {renderTF(
                      "vehicle_registration",
                      "Vehicle Registration",
                      "",
                      false,
                      selectedStaff && selectedStaff.vehicle_registration
                    )}
                    <Grid item xs={4} />
                    <Grid item xs={4} />

                    <Grid item xs={4}>
                      <DragAndDrop
                        title="TCA, Driver License, White/Blue Card,
Other Qualificatons"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <DragAndDrop title="TFN, Superannuation" />
                    </Grid>
                    <Grid item xs={8}>
                      <SectionDivider title="Emergency Contact" />
                    </Grid>
                    <Grid item xs={4} />
                    {renderTF(
                      "contact",
                      "Emergency Contact",
                      "",
                      false,
                      selectedStaff && selectedStaff.contact
                    )}

                    {renderSelect(
                      "relationship",
                      "Relationship",
                      "",
                      relationshipsOption
                    )}
                    <Grid item xs={4} />
                    {renderTF(
                      "phone",
                      "Phone Number",
                      "",
                      false,
                      selectedStaff && selectedStaff.phone
                    )}
                    <Grid item xs={4} />

                    <Grid item xs={4}>
                      <div className="anj-create-job-wrapper">
                        <div className="anj-create-job-inner-wrapper">
                          <Button
                            style={{
                              color: "white",
                              fontFamily: "futura-pt-demi",
                              fontSize: 18,
                              backgroundColor: "#A3C960",
                              width: 305,
                              borderRadius: 22
                            }}
                            onClick={() => handleSubmitButton()}
                          >
                            {`${selectedStaff ? "Edit" : "Register"} Staff`}
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddNewStaff;
