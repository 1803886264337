import { makeStyles } from "@material-ui/core/styles";

const HeaderStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    display: "flex",
    padding: "8px 0",
    fontSize: 22,
    color: "#535353"
  }
}));

export default HeaderStyles;
