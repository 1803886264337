import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import HeaderStyles from "./HeaderStyles";
import { MuiThemeProvider, Button } from "@material-ui/core";
import HeaderTheme from "./HeaderTheme";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import IconButton from "@material-ui/core/IconButton";
import AvatarIcon from "../Icons/AvatarIcon/AvatarIcon";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DatesRail from "../DatesRail/DatesRail";
import "./Header.css";
import { LOGOUT } from "../../../Redux/Actions/Types/Session/Session";
import PopOverMenu from "../Elements/PopoverMenu/PopOverMenu";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Redirect, Link } from "react-router-dom";

const getWeek = () => {
  var startOfWeek = moment()
    .startOf("week")
    .add("1", "day");
  var endOfWeek = moment()
    .endOf("week")
    .add("2", "days");

  var days = [];
  var day = startOfWeek;

  while (day <= endOfWeek) {
    days.push({ label: day.format("ddd Do"), date: day.toDate(), state: false });
    day = day.clone().add(1, "d");
  }

  return days;
};
const Header = () => {
  const classes = HeaderStyles();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch({ type: LOGOUT });
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };
  const [startDate, setStartDate] = useState();

  const profileOptions = [{ label: "Log out", action: handleLogout }];

  const handleGoBack = () => {
    return (
      <Redirect
        to={{
          pathname: "/"
        }}
      />
    );
  };

  const renderJobOptions = newJob => {
    return (
      <div className="header-options-wrapper">
        <Button
          className={classes.goBackButton}
          onClick={() => {
            handleGoBack();
          }}
        >
          <Link to="/" style={{ textDecoration: "none", display: "flex" }}>
            <ArrowBackIosIcon style={{ fontSize: 16 }} />
            <p>Go Back</p>
          </Link>
        </Button>
        <div className="header-options-divider"></div>
        {newJob ? <p>Add New Job</p> : <p>Edit Job Created</p>}
      </div>
    );
  };
  const renderStaffOptions = newJob => {
    return (
      <div className="header-options-wrapper">
        <Button
          className={classes.goBackButton}
          onClick={() => {
            handleGoBack();
          }}
        >
          <Link to="/" style={{ textDecoration: "none", display: "flex" }}>
            <ArrowBackIosIcon style={{ fontSize: 16 }} />
            <p>Go Back</p>
          </Link>
        </Button>
        <div className="header-options-divider"></div>
        { <p>Add New Staff</p>}
      </div>
    );
  };

  const renderDashboardOptions = () => {
    return (
      <Fragment>
        <div className="header-section-wrapper-1">
          <div className="header-calendar-input-wrapper">
            <IconButton style={{ paddingTop: 4 }}>
              <CalendarTodayIcon />
            </IconButton>
          </div>
          <DayPickerInput
            showPopperArrow={false}
            selected={startDate}
            onChange={date => setStartDate(date)}
            inputProps={{
              style: {
                padding: "8px 8px 8px 34px",
                border: "none",
                borderRadius: 6,
                background: startDate && "none",
                fontFamily: "futura-pt-book",
                fontSize: 14,
                color: "#7F7F7F",
                outline: "none"
              },
              placeholder: "Select a Date"
            }}
          />
        </div>
        <div className="header-section-wrapper-2">
          <DatesRail dates={getWeek()} />
        </div>
      </Fragment>
    );
  };

  const renderOptions = () => {
    switch (window.location.pathname) {
      case "/":
      case "/Jobs":
        return renderDashboardOptions();
      case "/Jobs/new":
        return renderJobOptions(true);
      case "/Jobs/edit":
        return renderJobOptions();
      case "/Staff/new":
        return renderStaffOptions();
      default:
        break;
    }
  };
  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={HeaderTheme}>
        <div className={classes.container}>
          {renderOptions()}
          <div className="header-section-wrapper-min">
            <div className={classes.menuContainer}>
              <div>
                <IconButton aria-label="notifications" style={{ padding: 4 }}>
                  <NotificationsNoneIcon />
                </IconButton>
              </div>
              <div style={{ padding: "4px 12px 4px 8px", color: "#535353" }}>
                <p> Francisco</p>
              </div>
              <div>
                <PopOverMenu
                  options={profileOptions}
                  icon={<AvatarIcon />}
                  iconStyle={{ backgroundColor: "#8FBE3E", padding: 4 }}
                />
              </div>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    </Fragment>
  );
};

export default Header;
