import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import JobDetailsStyles from "./JobDetailsStyles";
import { MuiThemeProvider } from "@material-ui/core";
import JobDetailsTheme from "./JobDetailsTheme";
import "./JobDetails.css";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Link } from "react-router-dom";
import { SELECT_JOB } from "../../../../../Redux/Actions/Types/Dashboard/Jobs";
import JobsActions from "../../../../../Redux/Actions/dispatches/Jobs/Jobs";

const JobDetails = ({ data, openStaffModal, setOpenStaffModal }) => {
  const classes = JobDetailsStyles();
  const dispatch = useDispatch();
  const loggedJobs = useSelector(state => state.dashboard.loggedJobs);
  const completedJobs = useSelector(state => state.dashboard.completedJobs);

  const getClientName = id => {
    switch (id) {
      default:
        return "GET DIRECTED";
    }
  };

  const findJobDetails = (jobID, array) => {

    var job = array.filter(obj => {
      return obj.id === jobID;
    });
    return job[0];
  };

  const handleOpenEdit = jobID => {
    dispatch({
      type: SELECT_JOB,
      payload: findJobDetails(jobID, loggedJobs)
    });
  };

  const handleOpenReport = jobID => {
    dispatch({
      type: SELECT_JOB,
      payload: findJobDetails(jobID, completedJobs)
    });
  };

  const handleOpenAddStaff = (jobID, popupState) => {
    dispatch({
      type: SELECT_JOB,
      payload: findJobDetails(jobID, loggedJobs)
    });
    setOpenStaffModal(true);
    popupState.close()
  };

  const handleDeleteButton = (selectedJob) => {
    dispatch(JobsActions.deleteJob(selectedJob.id));
  };

  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={JobDetailsTheme}>
        {data && (
          <div className={classes.container}>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flexGrow: 1 }}>
                <div
                  className={`job-details-id ${
                    0 === data.status
                      ? "incomplete"
                      : 1 === data.status
                        ? "in-progress"
                        : "completed"
                    }
                `}
                >
                  <p>{`job ID: GC-${data.id}`}</p>
                </div>
                <div style={{ paddingTop: 15 }}>
                  {data.shift_type ? (
                    data.shift_type === 0 ? (
                      <img src="images/rcs/sun.png" alt="" />
                    ) : (
                        <img src="images/rcs/moon.png" alt="" />
                      )
                  ) : ('')}
                </div>
              </div>
              {data.status !== 2 && (
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {popupState => (
                    <React.Fragment>
                      <IconButton
                        aria-label="delete"
                        {...bindTrigger(popupState)}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem
                          onClick={() => handleOpenEdit(data.id)}
                          className={classes.menuItem}
                        >
                          <Link to={"/Jobs/edit"} className={classes.link}>
                            <p>Edit Job</p>
                          </Link>
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            handleOpenAddStaff(data.id, popupState)
                          }
                          className={classes.menuItem}
                        >
                          <p>Assign staff</p>
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleDeleteButton(data)}
                          className={classes.menuItem}
                        >
                          <p>Delete </p>
                        </MenuItem>
                        <MenuItem
                          onClick={popupState.close}
                          className={classes.menuItem}
                        >
                          <p>Confirm Job</p>
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              )}
            </div>

            <div
              style={{
                textAlign: "left",
                padding: "0 8px 8px",
                fontSize: 20,
                lineHeight: "26px",
                color: "#535353"
              }}
            >
              <p>{data.address ? (`${data.address},`) : ('-')}{data.location ? (`${data.location}`) : ('')}</p>
            </div>

            <div
              style={{ display: "flex", textAlign: "left", padding: "0 8px" }}
            >
              <div className="job-details-info-wrapper">
                <div className="job-details-info-title">
                  <p>Client </p>
                </div>
                <div className="job-details-info-data">
                  {/* <p>{data.id_client}</p> */}
                  <p>{getClientName(data.id_client)}</p>
                </div>
              </div>

              {data.status !== 2 ? (
                <Fragment>
                  <div className="job-details-info-wrapper">
                    <div className="job-details-info-title">
                      <p>Contact</p>
                    </div>
                    <div className="job-details-info-data">
                      <p>{data.contact_number}</p>
                    </div>
                  </div>
                  <div className="job-details-info-wrapper">
                    <div className="job-details-info-title">
                      <p>Name</p>
                    </div>
                    <div className="job-details-info-data">
                      <p>{data.booking_name}</p>
                    </div>
                  </div>
                </Fragment>
              ) : (
                  <Fragment>
                    <div className="job-details-info-wrapper">
                      <div className="job-details-info-title">
                        <p>START TIME</p>
                      </div>
                      <div className="job-details-info-data">
                        <p>{data.time_start}</p>
                      </div>
                    </div>
                    <div className="job-details-info-wrapper">
                      <div className="job-details-info-title">
                        <p>FINISH TIME</p>
                      </div>
                      <div className="job-details-info-data">
                        <p>{data.time_finish}</p>
                      </div>
                    </div>
                  </Fragment>
                )}
            </div>

            <div className="job-details-info-divider"></div>

            <div style={{ display: "flex", padding: "0 8px 12px" }}>
              <div className="jobs-details-utcs-wrapper">
                <div className="jobs-details-utcs-title">
                  <p>{"N° UTES "}</p>
                </div>
                <div className="jobs-details-utcs-data">
                  <p>{data.number_utes ? (`${data.number_utes}`) : ""}</p>
                </div>
              </div>
              <div className="jobs-details-utcs-wrapper">
                <div className="jobs-details-utcs-title">
                  <p>{"N° TCS "}</p>
                </div>
                <div className="jobs-details-utcs-data">
                  {data.status === 1 ? (
                    <p style={{ color: "#A3C960" }}>
                      {data.number_trafic_current ? (`${data.number_trafic_current}`) : ('')}
                    </p>
                  ) : (
                      ""
                    )}
                  <p>
                    {data.status === 1 && data.number_trafic ? ("/") : ("")}
                    {data.number_trafic ? (`${data.number_trafic}`) : ("")}
                  </p>
                </div>
              </div>
              {data.time_start && data.status !== 2 ? (
                <Fragment>
                  <div className="jobs-details-utcs-wrapper">
                    <div className="jobs-details-utcs-title">
                      <p>{"START TIME"}</p>
                    </div>
                    <div className="jobs-details-utcs-data">
                      <p>{`${data.time_start}`}</p>
                    </div>
                  </div>
                </Fragment>
              ) : (
                  <Fragment>
                    <div className="jobs-details-utcs-wrapper">
                      <div className="jobs-details-utcs-title">
                        <Link
                          to="/jobs/report"
                          style={{ textDecoration: "none" }}
                        >
                          <p
                            onClick={() => handleOpenReport(data.id)}
                            style={{ color: "green", cursor: "pointer" }}
                          >
                            {"View Job report"}
                          </p>
                        </Link>
                      </div>
                    </div>
                  </Fragment>
                )}
              {data.tbc ? (
                <div
                  className="jobs-details-utcs-wrapper"
                  style={{ display: "block" }}
                >
                  <div className="jobs-details-tbc">{"TBC"}</div>
                </div>
              ) : (
                  <div />
                )}
            </div>
          </div>
        )}
      </MuiThemeProvider>
    </Fragment >
  );
};

export default JobDetails;
