import { makeStyles } from "@material-ui/core/styles";

const DragAndDropStyles = makeStyles(theme => ({
  uploadButton: {
    width: 100,
    background: "#A3C960",
    color: "white",
    borderRadius: 22,
    fontSize: 10.5
  }
}));

export default DragAndDropStyles;
