import { CREATE_NEW_JOB_FULFILLED, FETCH_JOBS_LIST_FULFILLED, FETCH_JOB_DETAIL_FULFILLED } from "../Actions/Types/Dashboard/Jobs";

const initialState = {
  jobs: undefined,
  detailedJob: undefined
};

const jobs = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_JOBS_LIST_FULFILLED: {
      return {
        ...state,
        jobs: action.payload && action.payload.jobs.data
      }
    }
    case FETCH_JOB_DETAIL_FULFILLED: {
      return {
        ...state,
        detailedJob: action.payload && action.payload.job
      }
    }
    case CREATE_NEW_JOB_FULFILLED: {
      return {
        ...state,
        createJob: action.payload && action.payload.job
      }
    }
    default:
      return state;
  }
};

export default jobs;
