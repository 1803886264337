import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  IconButton
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import SectionDivider from "../../../UI/Elements/SectionDivider/SectionDivider";
import ChipButton from "../../../UI/Elements/Buttons/ChipButton/ChipButton";
import "./AddStaffModal.css";
import AddStaffModalTheme from "./AddStaffModalTheme";
import { MuiThemeProvider } from "@material-ui/core";
import StaffActions from "../../../../Redux/Actions/dispatches/Staff";
import JobsAction from "../../../../Redux/Actions/dispatches/Jobs/Jobs"

const AddStaffModal = ({ opened, setOpened, staffs, setStaffs }) => {
  const staffList = useSelector(state => state.staff.staff);
  const selectedJob = useSelector(state => state.jobs.detailedJob)
  //const [innerStaffs, setInerStaffs] = useState([]);
  const [innerStaffs, setInerStaffs] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {

    if (!staffList) {
      dispatch(StaffActions.fetchStaff());
    }

    if (selectedJob) {
      setInerStaffs(selectedJob.staffs)
    }
  }, [dispatch, staffList, selectedJob]);

  const staffSwitch = () => {
    if (staffs) return staffs;
    else {
      return innerStaffs;
    }
  };
  const addMember = (member, relationship) => {
    var newStaffs = Array.from(staffSwitch());

    if (!newStaffs.find((e) => e.id === member.id)) {
      var newStaffMember = { ...member, relationship: relationship };
      newStaffs.push(newStaffMember);
      setInerStaffs(newStaffs)
    }
  };
  const deleteMember = (id) => {
    var newStaffs = staffSwitch().filter(e => e.id !== id);
    setInerStaffs(newStaffs)
  };
  const updateMembers = () => {
    dispatch(JobsAction.updateJobStaff(selectedJob.id, innerStaffs))
  };
  const style = theme => ({
    subTitle: {
      // position: 'absolute',
      // right: theme.spacing(1),
      top: theme.spacing(-5),
      alignContent: "space-between",
      color: theme.palette.background.paper
    },
    closeButton: {
      marginLeft: "auto",
      backgroundColor: "#A3C960",
      padding: "4px",
      color: theme.palette.background.paper
    }
  });

  return (
    <Fragment>
      <MuiThemeProvider theme={AddStaffModalTheme}>
        <Dialog
          fullWidth
          open={opened}
          onClose={() => setOpened(false)}
          style={{ color: "#E8E8E8" }}
        >
          <div className="add-staff-modal-over" style={style(AddStaffModalTheme).subTitle}>
            <p>Search, add or remove Traffic Controllers. Add staff or Close to exit</p>

            <IconButton aria-label="close" style={style(AddStaffModalTheme).closeButton} onClick={() => setOpened(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <DialogTitle>
            <SectionDivider title={"Assign Staff"} />
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={4}>
              <Grid item xs={5}>
                <div>
                  <p>Search Crew members</p>
                  <div>
                    <input
                      placeholder="Search for a crew member"
                      className="add-staff-search-input"
                    />
                    <div className="add-staff-crew-wrapper">
                      {staffList &&
                        staffList.map((member, index) => {
                          return (
                            <div
                              className="add-staff-member-content-wrapper"
                              key={`${member.id}-${index}`}
                            >
                              <div className="add-staff-member-name-wrapper">
                                <p>{member.name}</p>
                              </div>
                              <div className="add-staff-member-letters-wrapper">
                                <div
                                  className="add-staff-member-letter-wrapper"
                                  onClick={() => addMember(member, 0)}
                                >
                                  D
                                </div>
                                <div
                                  className="add-staff-member-letter-wrapper"
                                  onClick={() => addMember(member, 1)}
                                >
                                  S
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={7}>
                <div>
                  <p>Current Members Selected</p>
                  <div>
                    {staffSwitch() &&
                      staffSwitch().map((member, index) => {
                        return (
                          <ChipButton
                            title={member.name}
                            driver={member.relationship === 0}
                            active
                            id={member.id}
                            onDelete={deleteMember}
                            key={member.id}
                          />
                        );
                      })}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={{ width: "max-content", margin: "0 0 0 auto" }}>
                  <Button
                    onClick={updateMembers}
                    style={{
                      background: "#A3C960",
                      borderRadius: 25,
                      color: "white",
                      padding: "6px 24px",
                      margin: "24px 0"
                    }}
                  >
                    ADD STAFF
                  </Button>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    </Fragment>
  );
};

export default AddStaffModal;
