import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const LocationIcon = ({ props, className }) => {
  return (
    <SvgIcon {...props} className={className}>
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="sign-out-alt"
        className="svg-inline--fa fa-sign-out-alt fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
      >
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M2.7998 4.2C2.7998 1.8802 4.68 0 6.9998 0C9.3196 0 11.1998 1.8802 11.1998 4.2C11.1998 7.2002 6.9998 11.9 6.9998 11.9C6.9998 11.9 2.7998 7.2002 2.7998 4.2ZM6.9998 2.7002C7.8286 2.7002 8.49961 3.3712 8.49961 4.2C8.49961 5.0281 7.8279 5.6998 6.9998 5.6998C6.1717 5.6998 5.5 5.0288 5.5 4.2C5.5 3.3712 6.171 2.7002 6.9998 2.7002ZM0 14.7L1.96055 9.80005L3.63125 9.80005C3.97075 10.3117 4.3116 10.7856 4.6252 11.2L2.90664 11.2L2.06719 13.3L11.9328 13.3L11.0934 11.2L9.3748 11.2C9.6884 10.7856 10.0293 10.3117 10.3688 9.80005L12.0395 9.80005L14 14.7L0 14.7Z"
          id="Shape"
          fill="#000000"
          fillRule="evenodd"
          stroke="none"
        />
      </svg>
    </SvgIcon>
  );
};

export default LocationIcon;
