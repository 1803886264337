import {
  CREATE_NEW_JOB,
  FETCH_JOBS_LIST,
  SET_ACTIVE_JOBS_LIST,
  SET_COMPLETED_JOBS_LIST,
  EDIT_JOB,
  DELETE_JOB,
  FETCH_JOB_DETAIL,
  UPDATE_JOB_STAFF
} from "../../Types/Dashboard/Jobs";
import JobsRequests from "../../../../Requests/Jobs/Jobs";

let JobsActions = {
  fetchJobs() {
    return {
      type: FETCH_JOBS_LIST,
      payload: JobsRequests.fetchJobs()
    };
  },
  setActiveJobs(activeJobs) {
    return {
      type: SET_ACTIVE_JOBS_LIST,
      payload: activeJobs
    };
  },
  setCompletedJobs(completedJobs) {
    return {
      type: SET_COMPLETED_JOBS_LIST,
      payload: completedJobs
    };
  },
  createNewJob(data) {
    return {
      type: CREATE_NEW_JOB,
      payload: JobsRequests.createNewJob(data)
    };
  },
  editJob(id, data) {
    return {
      type: EDIT_JOB,
      payload: JobsRequests.editJob(id, data)
    };
  },
  deleteJob(data) {
    return {
      type: DELETE_JOB,
      payload: JobsRequests.deleteJob(data)
    };
  },
  jobDetail(id) {
    return {
      type: FETCH_JOB_DETAIL,
      payload: JobsRequests.jobDetail(id)
    };
  },
  updateJobStaff(id, staff) {
    return {
      type: UPDATE_JOB_STAFF,
      payload: JobsRequests.updateJobStaff(id, staff)
    };
  },
};

export default JobsActions;
