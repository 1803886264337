import { FETCH_JOBS_LIST_FULFILLED, FETCH_JOBS_LIST_PENDING, FETCH_JOBS_LIST_REJECTED, FETCH_JOB_DETAIL_FULFILLED, FETCH_JOB_DETAIL_PENDING, FETCH_JOB_DETAIL_REJECTED } from "../Actions/Types/Dashboard/Jobs";
import { FETCH_CLIENTS_PENDING, FETCH_CLIENTS_REJECTED, FETCH_CLIENTS_FULFILLED } from "../Actions/Types/Dashboard/Clients";

const initialState = {
  screen: undefined,
  loading: 0
};

const global = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLIENTS_PENDING:
    case FETCH_JOB_DETAIL_PENDING:
    case FETCH_JOBS_LIST_PENDING: {
      return {
        ...state,
        loading: state.loading + 1
      }
    }
    case FETCH_CLIENTS_REJECTED:
    case FETCH_CLIENTS_FULFILLED:
    case FETCH_JOB_DETAIL_REJECTED:
    case FETCH_JOB_DETAIL_FULFILLED:
    case FETCH_JOBS_LIST_REJECTED:
    case FETCH_JOBS_LIST_FULFILLED: {
      return {
        ...state,
        loading: state.loading - 1
      }
    }
    default:
      return state;
  }
};

export default global;
