import { FETCH_STAFF_LIST, DELETE_STAFF, CREATE_STAFF, EDIT_STAFF } from "../Types/Staff/Staff";
import StaffFetches from "../../../Requests/Staff/Staff";

let StaffActions = {
  fetchStaff() {
    return {
      type: FETCH_STAFF_LIST,
      payload: StaffFetches.fetchStaff()
    };
  },
  createStaff(data) {
    return {
      type: CREATE_STAFF,
      payload: StaffFetches.createStaff(data)
    };
  },
  editStaff(id, data) {
    return {
      type: EDIT_STAFF,
      payload: StaffFetches.editStaff(id, data)
    };
  },
  deleteStaff() {
    return {
      type: DELETE_STAFF,
      payload: StaffFetches.deleteStaff()
    };
  }
};

export default StaffActions;
