import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import StaffStyles from "./StaffStyles";
import "./Staff.css";
import Header from "../../UI/Header/Header";
import Sidebar from "../../UI/SideBar/Sidebar";
import SearchBarStaff from "../../UI/SearchBar/SearchBarStaff";
import ListView from "../Jobs/Dashboard/ListView/ListView";
import { useDispatch } from "react-redux";
import StaffActions from "../../../Redux/Actions/dispatches/Staff";

const Staff = () => {
  const classes = StaffStyles();

  const staffs = useSelector(state => state.staff.staff);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(StaffActions.fetchStaff());
  }, [dispatch]);

  return (
    <Fragment>
      <div className={classes.root}>
        <Sidebar />
        <div className={classes.content}>
          <Header />
          <SearchBarStaff />
          <ListView jobs={staffs} staff />
        </div>
      </div>
    </Fragment>
  );
};

export default Staff;
