import React from "react";
import { Grid } from "@material-ui/core";
import NewJob from "../../../../UI/Elements/Cards/NewJob/NewJob";
import JobDetailsList from "../../../../UI/Elements/Cards/JobDetailsList/JobDetailsList";
import StaffMemberList from "../../../../UI/Elements/Cards/StaffMemberList/StaffMemberList";

const ListView = ({ jobs, completed, staff }) => {
  return (
    <Grid container spacing={2} style={{ width: "calc(100% + 8px)" }}>
      {!completed && (
        <Grid item xs={12}>
          <NewJob list staff={staff} />
        </Grid>
      )}
      {jobs &&
        jobs.map((job, index) => {
          return (
            <Grid item xs={12} key={index}>
              {staff ? (
                <StaffMemberList data={job} />
              ) : (
                <JobDetailsList data={job} completed={completed} />
              )}
            </Grid>
          );
        })}
    </Grid>
  );
};

export default ListView;
