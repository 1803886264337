import { makeStyles } from "@material-ui/core/styles";
import {sidebarWidth} from "../../../config";

const sidebarStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    width: `calc(100% - ${sidebarWidth}px)`,
    marginRight: sidebarWidth
  },
  drawer: {
    width: sidebarWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: sidebarWidth
  },
  toolbar: {...theme.mixins.toolbar, padding: "32px 0"},
  content: {
    flexGrow: 1,
    backgroundColor: "#E8E8E8",
    padding: theme.spacing(3)
  }
}));

export default sidebarStyles;