import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "../../../UI/SideBar/Sidebar";
import SearchBar from "../../../UI/SearchBar/SearchBar";
import DashboardStyles from "./DashboardStyles";
import { IconButton } from "@material-ui/core";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import ViewListIcon from "@material-ui/icons/ViewList";

import "./Dashboard.css";
import GridView from "./GridView/GridView";
import ListView from "./ListView/ListView";
import Header from "../../../UI/Header/Header";
import AddStaffModal from "../../Staff/AddStaffModal/AddStaffModal";
import {
  SELECT_JOB
} from "../../../../Redux/Actions/Types/Dashboard/Jobs";
import JobsActions from "../../../../Redux/Actions/dispatches/Jobs/Jobs";

const Dashboard = () => {
  const classes = DashboardStyles();
  const dispatch = useDispatch();
  const loggedJobs = useSelector(state => state.dashboard.loggedJobs);
  const completedJobs = useSelector(state => state.dashboard.completedJobs);
  const searchResults = useSelector(state => state.dashboard.completedJobs);
  const utes = useSelector(state => state.dashboard.utes);
  const tcs = useSelector(state => state.dashboard.tcs);
  const loading = useSelector(state => state.dashboard.loading)
  const [gridView, setGridView] = useState(true);
  const [openStaffModal, setOpenStaffModal] = useState(false);

  const jobs = useSelector(state => state.jobs.jobs);
  const selectedJob = useSelector(state => state.dashboard.selectedJob);

  useEffect(() => {
    if (!jobs) {
      dispatch(JobsActions.fetchJobs());
    }
    else if (jobs && !loggedJobs && !completedJobs) {
      const activeJobs = jobs.filter(job => job.status === 0 || job.status === 1);
      const completedJobs = jobs.filter(job => job.status === 2);
      dispatch(JobsActions.setActiveJobs(activeJobs));
      dispatch(JobsActions.setCompletedJobs(completedJobs));
    }
    if (openStaffModal && selectedJob) {

      dispatch(JobsActions.jobDetail(selectedJob.id))
    }
    selectedJob ||
      dispatch({
        type: SELECT_JOB,
        payload: undefined
      });
  }, [dispatch, loggedJobs, completedJobs, selectedJob, jobs, openStaffModal]);



  return (
    <Fragment>
      <div className={classes.root}>
        <Sidebar />
        <div className={classes.content}>
          <Header />
          <SearchBar />
          <AddStaffModal
            opened={openStaffModal}
            setOpened={setOpenStaffModal}
          />
          <div
            style={{
              width: "100%",
              padding: "8px 0",
              overflow: "hidden"
            }}
          >
            <div className={classes.jobCountInfoContainer}>
              <p style={{ lineHeight: 2 }}>{"Nº UTES"}</p>
              <div className={classes.circleNumber}>
                <p>{utes}</p>
              </div>
              <div style={{ paddingRight: "8px" }}>|</div>
              <p style={{ lineHeight: 2 }}>{"N° TC"}</p>
              <div className={classes.circleNumber}>
                <p>{tcs}</p>
              </div>
            </div>
            <div style={{ float: "right" }}>
              <IconButton
                aria-label="carousel"
                style={gridView ? { backgroundColor: "#8FBE3E" } : {}}
                onClick={() => setGridView(true)}
              >
                <ViewModuleIcon />
              </IconButton>
              <IconButton
                aria-label="viewlist"
                onClick={() => setGridView(false)}
                style={!gridView ? { backgroundColor: "#8FBE3E" } : {}}
              >
                <ViewListIcon />
              </IconButton>
            </div>
          </div>
          <div
            style={{
              padding: "12px 0",
              overflowY: "auto",
              maxHeight: gridView ? 366 : 354
            }}
          >
            {gridView ? (
              <GridView
                jobs={loggedJobs}
                openStaffModal={openStaffModal}
                setOpenStaffModal={setOpenStaffModal}
              />
            ) : (
                <ListView
                  jobs={loggedJobs}
                  openStaffModal={openStaffModal}
                  setOpenStaffModal={setOpenStaffModal}
                />
              )}
          </div>
          <div>
            <div className="dashboard-ljc">
              <p>Latest Jobs Completed</p>
              <div className="divider" />
            </div>
            <div
              style={{ padding: "12px 0", overflowY: "auto", maxHeight: 366 }}
            >
              {gridView ? (
                <GridView
                  jobs={
                    searchResults && searchResults.resultsCompletedJobs
                      ? searchResults.resultsCompletedJobs
                      : completedJobs
                  }
                  completed
                  openStaffModal={openStaffModal}
                  setOpenStaffModal={setOpenStaffModal}
                />
              ) : (
                  <ListView
                    jobs={completedJobs}
                    completed
                    openStaffModal={openStaffModal}
                    setOpenStaffModal={setOpenStaffModal}
                  />
                )}
            </div>
          </div>
        </div>
      </div>

    </Fragment>

  );
};

export default Dashboard;
