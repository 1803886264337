import {
  FETCH_STAFF_LIST_FULFILLED,
  SELECT_STAFF
} from "../Actions/Types/Staff/Staff";

const initialState = {
  staff: undefined,
  selectedStaff: undefined
};

const staff = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STAFF_LIST_FULFILLED: {
      return {
        ...state,
        staff: action.payload.staffs.data
      };
    }
    case SELECT_STAFF: {
      return {
        ...state,
        selectedStaff: action.payload
      };
    }
    default:
      return state;
  }
};

export default staff;
