import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddNewJobsStyles from "./AddNewJobStyles";
import "./AddNewJob.css";
import { Grid, Button } from "@material-ui/core";
import Sidebar from "../../../UI/SideBar/Sidebar";
import JobForm from "./JobForm";
import SectionDivider from "../../../UI/Elements/SectionDivider/SectionDivider";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ChipButton from "../../../UI/Elements/Buttons/ChipButton/ChipButton";
import DragAndDrop from "../../../UI/Elements/DragAndDrop/DragAndDrop";
import Header from "../../../UI/Header/Header";
import DashboardActions from "../../../../Redux/Actions/dispatches/Dashboard/Dashboard";
import AddStaffModal from "../../Staff/AddStaffModal/AddStaffModal";
import JobsActions from "../../../../Redux/Actions/dispatches/Jobs/Jobs";

const jobAddressOptions = [
  { id: 0, name: "Rockell road" },
  { id: 1, name: "Frank Avenue" },
  { id: 2, name: "Boulevard of broken dreams" }
];

const jobTypeOptions = [
  { id: 0, name: "Traffic Control" },
  { id: 1, name: "Labour Hire" },
  { id: 2, name: "Vaccuum Excavation" }
];

const AddNewJob = () => {
  const classes = AddNewJobsStyles();
  const selectedJob = useSelector(state => state.dashboard.selectedJob);
  const clients = useSelector(state => state.dashboard.clients);
  const currentLocation = window.location.pathname;
  const [staffs, setStaffs] = useState([]);
  const [permits, setPermits] = useState([]);
  const [tgs, setTgs] = useState([]);
  const dispatch = useDispatch();
  const [openStaffModal, setOpenStaffModal] = useState(false);

  useEffect(() => {
    if (!selectedJob && currentLocation !== "/Jobs/new") {
      window.location.href = "/";
    }
    if (clients.length === 0) {
      dispatch(DashboardActions.fetchClients());
    }
  }, [selectedJob, currentLocation, clients, dispatch]);

  const handleSubmitButton = () => {
    document
      .getElementById("job-form")
      .dispatchEvent(new Event("submit", { cancelable: true }));
  };

  const handleDeleteButton = (selectedJob) => {
    dispatch(JobsActions.deleteJob(selectedJob.id));
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <Sidebar />
        <div className={classes.content}>
          <Header />
          <SectionDivider
            title={`${selectedJob ? "Edit Job" : "Add New Job"}`}
          />
          <AddStaffModal
            opened={openStaffModal}
            setOpened={setOpenStaffModal}
            staffs={staffs}
            setStaffs={setStaffs}
          />
          <JobForm
            jobAddressOptions={jobAddressOptions}
            jobTypeOptions={jobTypeOptions}
            selectedJob={selectedJob}
            clients={clients}
            staffs={staffs}
            permits={permits}
            setPermits={setPermits}
            tgs={tgs}
            setTgs={setTgs}
          />
          <SectionDivider title="Assign Staff" jobID />
          <section>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="anj-staff-new-staff-wrapper">
                  <Button className={classes.createJob} style={{ width: 220 }} onClick={() => setOpenStaffModal(true)}>
                    <div className="anj-add-circle-wrapper">
                      <AddCircleIcon />
                    </div>
                    {"Assign new staff"}
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="anj-staff-member-wrapper">
                  {staffs.map((data, index) => {
                    return (
                      <ChipButton
                        key={`${data}-${index}`}
                        action={() => {}}
                        title={data}
                        driver={index % 2 === 0}
                        active={index === 0}
                      />
                    );
                  })}
                </div>
              </Grid>
              <Grid item xs={4}>
                <DragAndDrop title="Permits" setFilesState={setPermits}/>
              </Grid>
              <Grid item xs={4}>
                <DragAndDrop title="TGS" setFilesState={setTgs} />
              </Grid>
              <Grid item xs={4}>
                <div className="anj-create-job-wrapper">
                  <div className="anj-create-job-inner-wrapper">
                    {selectedJob && selectedJob.status !== 2 && (
                      <div style={{ padding: "12px 0" }}>
                        <Button className={classes.createJob}
                        onClick={() => handleDeleteButton(selectedJob)}>
                          Delete Job
                        </Button>
                      </div>
                    )}
                    <Button
                      className={classes.createJob}
                      onClick={() => handleSubmitButton()}
                    >{`${
                      selectedJob
                        ? selectedJob.status === 2
                          ? "Export"
                          : "Edit"
                        : "Create"
                    } Job`}</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </section>
        </div>
      </div>
    </Fragment>
  );
};

export default AddNewJob;
