import React, { Fragment } from "react";
import Button from "@material-ui/core/Button";
import { useSelector, useDispatch } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import SearchBarStyles from "./SearchBarStyles";
import { MuiThemeProvider, TextField } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import SearchBarTheme from "./SearchBarTheme";
import "./SearchBar.css";
import { SET_SEARCH_RESULTS } from "../../../Redux/Actions/Types/Dashboard/Jobs";

const SearchBarJobs = () => {
  const classes = SearchBarStyles();
  const dispatch = useDispatch();
  const loggedJobs = useSelector(state => state.dashboard.loggedJobs);
  const completedJobs = useSelector(state => state.dashboard.completedJobs);

  const searchTerm = term => {
    dispatch({
      type: SET_SEARCH_RESULTS,
      payload: {
        resultsLoggedJobs: loggedJobs.filter(
          lj => !lj.id.includes(term) || !lj.name.includes(term)
        ),
        resultsCompletedJobs: completedJobs.filter(
          lj => !lj.id.includes(term) || !lj.name.includes(term)
        )
      }
    });
  };

  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={SearchBarTheme}>
        <div className={classes.container}>
          <Form
                onSubmit={formObj => {
                  //   dispatch({type: SESSION_REQUEST,
                  //     payload: SessionFetches.requestLogin(formObj)
                  // })}
                  //handleLoginRequest(formObj);
                }}
              >
                {({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}  className={classes.container}>
                    <Grid className={classes.container}>
                      <Grid item xs={4} style={{marginTop:"-8px",textAlign:"left"}}>                          
                        <TextField
                            variant="outlined"
                            className={classes.searchTextField}
                            onChange={event => searchTerm(event.target.value)}
                            placeholder="Search"
                        ></TextField>
                        <Button className={classes.search} type="submit">
                          SEARCH
                        </Button>
                      </Grid>
                      <Grid item xs={2}>
                        <div className="login-rem-for-wrapper">
                          <div className="login-rem-item-wrapper">
                            <label className="login-rem-item-label-wrapper">
                              <Field
                                name="tc"
                                component="input"
                                type="checkbox"
                                defaultValue={false}
                                className="login-remember-me"
                              />
                              Traffic Controller
                            </label>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={2}>
                        <div className="login-rem-for-wrapper">
                          <div className="login-rem-item-wrapper">
                            <label className="login-rem-item-label-wrapper">
                              <Field
                                name="jobs"
                                component="input"
                                type="checkbox"
                                defaultValue={false}
                                className="login-remember-me"
                                onClick={console.log('ok')}
                              />
                              Jobs
                            </label>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Form>     
        </div>
      </MuiThemeProvider>
    </Fragment>
  );
};

export default SearchBarJobs;
