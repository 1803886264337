import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import MapStyles from "./MapLocationStyles";
import { MuiThemeProvider, TextField } from "@material-ui/core";
import "./MapLocation.css";
//import Marker from "./MapMarker";
import StaffActions from "../../../Redux/Actions/dispatches/Staff";
import { GoogleMap, withScriptjs, withGoogleMap, Marker, InfoWindow } from "react-google-maps";
import credentials from "../../../credentials";
import JobsActions from "../../../Redux/Actions/dispatches/Jobs/Jobs";

const Map = withScriptjs(withGoogleMap((props) => {
  const classes = MapStyles();
  const dispatch = useDispatch();

  const jobs = useSelector(state => state.jobs.jobs);
  const staffs = useSelector(state => state.staff.staff);

  const iconStaff = { url: '../../../../images/marker-jobs.png', scaledSize: { width: 32, height: 32 } };
  const iconRed = { url: '../../../../images/red-marker.png', scaledSize: { width: 23, height: 35 } };
  const iconGreen = { url: '../../../../images/green-marker.png', scaledSize: { width: 23, height: 35 } };
  
  const markers = [ 
    { position: {lat: -28.0, lng: 153.4}, status: true, showInfo: false, icon: { path: 'M24.5 9h-8.1l-2.9-7.7C13.4 1.1 13.2 1 13 1c-0.2 0-0.4 0.1-0.5 0.3L9.6 9H1.5C1' } },
    { position: {lat: -28.0, lng: 153.41}, status: false, showInfo: false, icon: { path: 'M24.5 9h-8.1l-2.9-7.7C13.4 1.1 13.2 1 13 1c-0.2 0-0.4 0.1-0.5 0.3L9.6 9H1.5C1' } }
  ]

  const markers2 = [ 
    { position: {lat: -28.01, lng: 153.4}, showInfo: false, icon: { path: 'M24.5 9h-8.1l-2.9-7.7C13.4 1.1 13.2 1 13 1c-0.2 0-0.4 0.1-0.5 0.3L9.6 9H1.5C1' } },
    { position: {lat: -28.02, lng: 153.4}, showInfo: false, icon: { path: 'M24.5 9h-8.1l-2.9-7.7C13.4 1.1 13.2 1 13 1c-0.2 0-0.4 0.1-0.5 0.3L9.6 9H1.5C1' } }
  ]

  useEffect(() => {
    if (!jobs) {
      dispatch(JobsActions.fetchJobs());
    }
    if (!staffs) {
      dispatch(StaffActions.fetchStaff());
    }
  }, [dispatch]);

  return (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={{ lat: -28.0, lng: 153.4 }}>
        {markers.map((marker) => {
          return(
          <Marker
              icon={ marker.status ? iconGreen : iconRed }
              position={marker.position}
              name="My Marker"
          />
          );
        })}
        {markers2.map((marker) => {
          return(
          <Marker
              icon={iconStaff}
              position={marker.position}
              name="My Marker"
          />
          );
        })}
    </GoogleMap>
  );
}));

export default Map;
