let StaffFetches = {
  fetchStaff(data) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/staff/list`, {
      method: "GET",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://getdirected.nonstopdevs.com",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
        }`
      }
    })
      .then(res => res.json())
      .catch(error => {});
  },
  createStaff(data) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/staff/create`, {
      method: "POST",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://getdirected.nonstopdevs.com",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
        }`
      }
    })
      .then(res => {
        if(res.status == 200 || res.status == 201){
          window.location.href = '/'
        }
        else {
          alert('Ha ocurrido un error.');
          return res.json();
        }
      })
      .catch(error => {});
  },
  editJob(id, data) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/staff/edit/${id}`, {
      method: "POST",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://getdirected.nonstopdevs.com",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
          }`
      }
    })
      .then(res => {
        if(res.status == 200 || res.status == 201){
          window.location.reload(true); 
        }
        else {
          alert('Ha ocurrido un error.');
          return res.json();
        }
      })
      .catch(error => { });
  },
  deleteStaff(id) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/staff/delete/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://getdirected.nonstopdevs.com",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${
          localStorage.getItem("supersecretkey")
            ? localStorage.getItem("supersecretkey")
            : sessionStorage.getItem("supersecretkey")
        }`
      }
    })
      .then(res => {
        if(res.status == 200 || res.status == 201){
          window.location.reload(true); 
        }
        else {
          alert('Ha ocurrido un error.');
          return res.json();
        }
      })
      .catch(error => {});
  }
};

export default StaffFetches;
