import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import DaysPicker from "../../../UI/Elements/DaysPicker/DaysPicker";
import JobsRequests from "../../../../Requests/Jobs/Jobs";
import moment from "moment";
import PlacesAutocomplete from "react-places-autocomplete";
import JobsActions from "../../../../Redux/Actions/dispatches/Jobs/Jobs";

const renderTF = (fieldName, title, placeholder, disabled, value) => {
  return (
    <Grid item xs={4}>
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <Field name={fieldName} defaultValue={value && value}>
        {({ input }) => (
          <input
            disabled={disabled}
            placeholder={placeholder}
            type="text"
            {...input}
            className={`add-new-job-input-tf ${disabled &&
              "job-form-disabled"}`}
          />
        )}
      </Field>
    </Grid>
  );
};

const renderSelect = (
  fieldName,
  title,
  placeholder,
  options,
  disabled,
  big
) => {
  return (
    <Grid item xs={big ? 8 : 4}>
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <Field name={fieldName}>
        {props => (
          <select
            {...props.input}
            className="add-new-job-input-select"
            disabled={disabled}
          >
            <option value={""}>{"Select an option"}</option>
            {options.map((option, index) => {
              return (
                <option key={`${option.id}-${index}`} value={option.id}>
                  {option.name}
                </option>
              );
            })}
          </select>
        )}
      </Field>
    </Grid>
  );
};

const renderTA = (fieldName, title, placeholder, disabled, value) => {
  return (
    <Grid item xs={4}>
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <Field name={fieldName} defaultValue={value && value}>
        {props => (
          <textarea
            {...props.input}
            className="add-new-job-input-textarea"
            disabled={disabled}
          ></textarea>
        )}
      </Field>
    </Grid>
  );
};

const renderCHArray = (fieldName, title, placeholder, disabled) => {
  return (
    <Grid item xs={4}>
      <div className="add-new-job-field-title">
        <p>{title}</p>
      </div>
      <div className="add-new-job-field-cb-wrapper">
        <label className="add-new-job-field-cb-label">
          <Field name={fieldName} component="input" type="radio" value={"0"}>
            {props => (
              <input
                {...props.input}
                disabled={disabled}
                className="add-new-job-field-cb"
              ></input>
            )}
          </Field>
          Day
        </label>
        <label className="add-new-job-field-cb-label">
          <Field name={fieldName} component="input" type="radio" value={"1"}>
            {props => (
              <input
                {...props.input}
                disabled={disabled}
                className="add-new-job-field-cb"
              ></input>
            )}
          </Field>
          Night
        </label>
      </div>
    </Grid>
  );
};

const JobForm = ({
  jobAddressOptions,
  jobTypeOptions,
  selectedJob,
  clients,
  staffs,
  permits,
  tgs
}) => {
  const [selectedDates, setSelectedDates] = useState([]);
  
  //const loggedJobs = useSelector(state => state.jobs.createJob);

  const dispatch = useDispatch();

  const isFormDisabled = () => {
    return selectedJob && selectedJob.status === 2;
  };
  const today = moment().format("YYYY-MM-DD");

  const handleJobCreationProcess = formObj => {    
    formObj["address"] = address;
    
    if (!selectedJob) {
      var forms = selectedDates.map(date => {
        formObj["start_date"] = undefined;
        formObj["tbc"] = undefined;
        formObj["start_date"] = date.date;
        formObj["tbc"] = date.tbc;
        formObj["staffs"] = staffs;
        formObj["permits"] = permits;
        formObj["tgs"] = tgs;
        formObj["status"] = 0;
        formObj["time_start"] = today;
        return formObj;
      });
      forms.map(form => dispatch(JobsActions.createNewJob(form)));
    } 
    //else if (selectedJob.status === 1) {      
      // JobsRequests.editJob(selectedJob.id, formObj);
    //}
    else {      
      dispatch(JobsActions.editJob(selectedJob.id, formObj));
    }

  };
  
  const [address, setAddress] = React.useState("");

  const handleSelect = async(value) => {
    setAddress(value);
  }

  return (
    <div style={{ padding: " 8px 0" }}>
      <Form
        onSubmit={formObj => {
          handleJobCreationProcess(formObj);
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} id="job-form">
            <Grid container spacing={4}>
              {!selectedJob ? (
                <Grid item xs={8}>
                  <div className="add-new-job-field-title">
                    <p>{"Select a client"}</p>
                  </div>
                  <Field name={"client"}>
                    {props => (
                      <select
                        {...props.input}
                        className="add-new-job-input-select"
                      >
                        {clients &&
                          clients.map((option, index) => {
                            return (
                              <option
                                key={`${option}-${index}`}
                                value={option.id}
                              >
                                {option.name}
                              </option>
                            );
                          })}
                      </select>
                    )}
                  </Field>
                </Grid>
              ) : (
                <Grid item xs={8} />
              )}
              {<Grid item />}
              {renderTF(
                "gtdc",
                "Booking Taken By (GTDC)",
                "GTDC",
                isFormDisabled(),
                selectedJob && selectedJob.gtdc
              )}
              {
                //   renderTF(
                //   "date",
                //   "Date Booking Made",
                //   "",
                //   true,
                //   new Date().toLocaleDateString()
                // )
                <Grid item xs={4}>
                  <div className="add-new-job-field-title">
                    <p>{"Date Booking Made"}</p>
                  </div>
                  <Field name={"date"} defaultValue={today}>
                    {({ input }) => (
                      <input
                        disabled={true}
                        placeholder={"placeholder"}
                        type="text"
                        {...input}
                        className={`add-new-job-input-tf ${"job-form-disabled"}`}
                      />
                    )}
                  </Field>
                </Grid>
              }
              {/* {renderTF("client", "Client", "Get Directed", isFormDisabled())} */}
              {<Grid item xs={4} />}
              {renderTF(
                "booking_name",
                "Booking Contact Name",
                "Bobby",
                isFormDisabled(),
                selectedJob && selectedJob.booking_name
              )}
              {renderTF(
                "contact_number",
                "Contact Number",
                "1100012312",
                isFormDisabled(),
                selectedJob && selectedJob.contact_number
              )}
              <Grid item xs={4}>
                <div className="add-new-job-field-title">
                  <p>Address</p>
                </div>
                <PlacesAutocomplete                         
                  value={address}
                  onChange={setAddress}
                  onSelect={handleSelect}>
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading })=>(
                    <div>
                      <input 
                        className="add-new-job-input-tf address" 
                        {...getInputProps({ placeholder: "Type address"})}/>
                      <div className="address-section">
                        {loading 
                          ? <div className="address-suggestion">
                                ...loading
                            </div> 
                          : ''
                        }
                        {suggestions.map((suggestion) => {
                          const style={ backgroundColor: suggestion.active? '#84d1f1' : '#fff'};
                          return <div
                                    className="address-suggestion" 
                                    {...getSuggestionItemProps(suggestion, {style})}>
                                      {suggestion.description}
                                </div>
                        })}
                      </div>
                    </div>
                    )}
                </PlacesAutocomplete>
              </Grid>
              {renderSelect(
                "job_type",
                "Type Of job",
                "",
                jobTypeOptions,
                isFormDisabled()
              )}
              {renderCHArray("shift_type", "Shift", "", isFormDisabled())}
              {selectedJob ? (
                <Grid item xs={4} />
              ) : (
                <Grid item xs={4}>
                  <div className="add-new-job-field-title">
                    <p>{"Select date (s)"}</p>
                  </div>
                  <div className="add-new-job-dates-picker-wrapper">
                    <div className="picker">
                      <DaysPicker
                        showTBCRail
                        selectedDates={selectedDates}
                        setSelectedDates={setSelectedDates}
                      />
                    </div>
                  </div>
                </Grid>
              )}
              {renderTF(
                "time_req_site",
                "Time Req Onsite",
                "",
                isFormDisabled(),
                selectedJob && selectedJob.time_req_site
              )}
              {renderTF(
                "location",
                "Location of the UTE",
                "",
                isFormDisabled(),
                selectedJob && selectedJob.location
              )}
              {<Grid item />}
              {renderTF(
                "number_utes",
                "Number of UTES",
                "",
                isFormDisabled(),
                selectedJob && selectedJob.number_utes
              )}
              {renderTF(
                "number_trafic",
                "Number of Traffic Controllers",
                "",
                isFormDisabled(),
                selectedJob && selectedJob.number_trafic
              )}
              {<Grid item />}
              {renderTA(
                "setup_required",
                " Setup Required",
                "",
                isFormDisabled(),
                selectedJob && selectedJob.setup_required
              )}
              {renderTA(
                "notes",
                "Notes",
                "",
                isFormDisabled(),
                selectedJob && selectedJob.notes
              )}
            </Grid>
          </form>
        )}
      </Form>
    </div>
  );
};

export default JobForm;
