import React from "react";
import { useSelector } from "react-redux";
import "./App.css";
import "./font.css";
import Dashboard from "./Components/Layout/Jobs/Dashboard/Dashboard";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Login from "./Components/Layout/Login/Login";
import Staff from "./Components/Layout/Staff/Staff";
import Clients from "./Components/Layout/Clients/Clients";
import Location from "./Components/Layout/Location/Location";
import AddNewJob from "./Components/Layout/Jobs/Add/AddNewJob";
import AddNewStaff from "./Components/Layout/Staff/AddNewStaff/AddNewStaff";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppStyles from "./AppStyles";

function PrivateRoute({ children, logged, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        logged ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}
const App = () => {
  const logged =
    useSelector(state => state.session.logged) ||
    (localStorage.getItem("supersecretkey") ||
      sessionStorage.getItem("supersecretkey"));

  const classes = AppStyles();

  const loading = useSelector(state => state.global.loading);
  const isLoading = () => {
    console.log("loading ", loading)
    return loading !== 0
  }

  return (
    <div className="App">
      <Router>
        <Switch>
          <PrivateRoute logged={logged} exact path="/">
            <Dashboard />
          </PrivateRoute>

          <PrivateRoute logged={logged} exact path="/jobs">
            <Dashboard />
          </PrivateRoute>

          <PrivateRoute logged={logged} exact path="/jobs/edit">
            <AddNewJob />
          </PrivateRoute>

          <PrivateRoute logged={logged} exact path="/jobs/report">
            <AddNewJob />
          </PrivateRoute>
          <PrivateRoute logged={logged} exact path="/jobs/new">
            <AddNewJob />
          </PrivateRoute>
          <PrivateRoute logged={logged} exact path="/staff">
            <Staff />
          </PrivateRoute>
          <PrivateRoute logged={logged} exact path="/staff/new">
            <AddNewStaff />
          </PrivateRoute>
          <PrivateRoute logged={logged} exact path="/staff/edit">
            <AddNewStaff />
          </PrivateRoute>
          <PrivateRoute logged={logged} exact path="/clients">
            <Clients />
          </PrivateRoute>
          <PrivateRoute logged={logged} exact path="/location">
            <Location />
          </PrivateRoute>
          <Route exact path="/login">
            <Login />
          </Route>
        </Switch>
      </Router>
      <Backdrop className={classes.backdrop} open={isLoading()} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default App;
