import React, { Fragment } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import JobDetailsStyles from "./JobDetailsListStyles";
import { MuiThemeProvider } from "@material-ui/core";
import JobDetailsTheme from "./JobDetailsListTheme";
import "./JobDetailsList.css";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SELECT_JOB } from "../../../../../Redux/Actions/Types/Dashboard/Jobs";
import JobsActions from "../../../../../Redux/Actions/dispatches/Jobs/Jobs";

const JobDetailsList = data => {
  const classes = JobDetailsStyles();
  const getClientName = id => {
    switch (id) {
      default:
        return "Get Directed";
    }
  };
  const dispatch = useDispatch();
  const completedJobs = useSelector(state => state.dashboard.completedJobs);

  const findJobDetails = (jobID, array) => {
    var job = array.filter(obj => {
      return obj.id === jobID;
    });
    return job[0];
  };

  const handleOpenReport = jobID => {
    dispatch({
      type: SELECT_JOB,
      payload: findJobDetails(jobID, completedJobs)
    });
    return;
  };

  const handleDeleteButton = (selectedJob) => {
    dispatch(JobsActions.deleteJob(selectedJob.id));
  };

  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={JobDetailsTheme}>
        <div className={classes.container}>
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", flex: 2, maxWidth: 500 }}>
              <div
                className={`job-details-id ${
                  0 === data.data.status
                    ? "incomplete"
                    : 1 === data.data.status
                    ? "in-progress"
                    : "completed"
                }
                `}
              >
                <p>{`job ID: GC-${data.data.id}`}</p>
              </div>

              <div
                style={{
                  textAlign: "left",
                  padding: "10px 8px 8px",
                  fontSize: 16,
                  lineHeight: "26px",
                  color: "#535353"
                }}
              >
                <p> {data.data.address}</p>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                textAlign: "left",
                padding: "6px 8px",
                flex: 3
              }}
            >
              <div className="job-details-info-list-wrapper">
                <div className="job-details-info-list-title">
                  <p>Client </p>
                </div>
                <div className="job-details-info-list-data">
                  <p>{getClientName(data.data.id_client)}</p>
                </div>
              </div>
              <div className="job-details-info-list-divider" />

              <div className="job-details-info-list-wrapper">
                <div className="job-details-info-list-title">
                  <p>Contact</p>
                </div>
                <div className="job-details-info-list-data">
                  <p>{data.data.contact_number}</p>
                </div>
              </div>
              <div className="job-details-info-list-divider" />

              <div className="job-details-info-list-wrapper">
                <div className="job-details-info-list-title">
                  <p>Name</p>
                </div>
                <div className="job-details-info-list-data">
                  <p>{data.data.booking_name}</p>
                </div>
              </div>
              <div className="job-details-info-list-divider" />

              <div className="job-details-info-list-wrapper">
                <div className="job-details-info-list-title">
                  <p>utes</p>
                </div>
                <div className="job-details-info-list-data">
                  <p>{data.data.number_utes}</p>
                </div>
              </div>
              <div className="job-details-info-list-divider" />

              <div className="job-details-info-list-wrapper">
                <div className="job-details-info-list-title">
                  <p>tcs</p>
                </div>
                <div className="job-details-info-list-data">
                  <div
                    style={{
                      width: "min-content",
                      display: "flex",
                      margin: "auto"
                    }}
                  >
                    {data.data.status === 1 ? (
                    <p style={{ color: "#A3C960" }}>
                      {data.data.number_trafic_current ? (`${data.data.number_trafic_current}`) : ('')}
                    </p>
                  ) : (
                    ""
                  )}
                  <p>
                    {data.data.status === 1 && data.data.number_trafic ? ("/") : ("")}
                    {data.data.number_trafic ? (`${data.data.number_trafic}`) : ("")}
                  </p>
                  </div>
                </div>
              </div>
              {data.data.status === 1 ? 
                (<div className="job-details-info-list-divider" />) : " "
              }
              {data.data.status === 1 ? (
                <div className="job-details-info-list-wrapper">
                  <div className="job-details-info-list-title">
                    <p>start</p>
                  </div>
                  <div className="job-details-info-list-data">
                    <p>{data.data.time_start}</p>
                  </div>
                </div>
              ) : (
                <div className="job-details-info-list-wrapper"></div>
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse"
                }}
              >
                {data.data.status !== 2 ? (
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {popupState => (
                      <React.Fragment>
                        <IconButton
                          aria-label="delete"
                          {...bindTrigger(popupState)}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                        <Menu {...bindMenu(popupState)}>
                          <MenuItem
                            onClick={popupState.close}
                            className={classes.menuItem}
                          >
                            <Link to={"/Jobs/edit"} className={classes.link}>
                              <p>Edit Job</p>
                            </Link>
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleDeleteButton(data.data)}
                            className={classes.menuItem}
                          >
                            <p>Assign staff</p>
                          </MenuItem>
                          <MenuItem
                            onClick={popupState.close}
                            className={classes.menuItem}
                          >
                            <p>Delete </p>
                          </MenuItem>
                          <MenuItem
                            onClick={popupState.close}
                            className={classes.menuItem}
                          >
                            <p>Confirm Job</p>
                          </MenuItem>
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>
                ) : (
                  <div className="job-details-view-job-report-wrapper">
                    <Link to="/jobs/report" style={{ textDecoration: "none" }}>
                      <p
                        onClick={() => handleOpenReport(data.data.id)}
                        style={{ color: "green", cursor: "pointer" }}
                      >
                        {" "}
                        View Job Report
                      </p>
                    </Link>
                  </div>
                )}
              </div>
              <div
                className="jobs-details-utcs-wrapper"
                style={{ display: "block" }}
              >                
                <div className="jobs-details-list-tbc">
                  {data.data.tbc ? ("TBC"): <div/>}
                </div>                
              </div>
              <div style={{ paddingTop: 15 }}>
                {data.data.shift_type ? (
                  data.data.shift_type === 0 ? (
                    <img src="images/rcs/sun.png" alt="" />
                  ) : (
                    <img src="images/rcs/moon.png" alt="" />
                  )
                ) : <div/>}
              </div>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    </Fragment>
  );
};

export default JobDetailsList;
