import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import LocationStyles from "./LocationStyles";
import "./Location.css";
import Header from "../../UI/Header/Header";
import Sidebar from "../../UI/SideBar/Sidebar";
import MapLocation from "../../UI/MapLocation/MapLocation";
import SearchBarLocation from "../../UI/SearchBar/SearchBarLocation";
import credentials from "../../../credentials";
import mapSettings from '../../UI/MapLocation/MapSettingsJson.json';

const mapUrl = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${credentials.mapsKey}`

const Location = () => {
  const classes = LocationStyles();

  const defaultMapOptions = {
    styles: mapSettings
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <Sidebar />
        <div className={classes.content}>
          <SearchBarLocation />
          <Header />
          <MapLocation
            googleMapURL={mapUrl}
            containerElement={<div style={{ height: '38rem' }} />}
            mapElement={<div style={{ height: '100%' }} />}
            loadingElement={<p>Cargando</p>}   
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Location;
