import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import LoginStyles from "./LoginStyles";
import { MuiThemeProvider } from "@material-ui/core";
import "./Login.css";
import Container from "@material-ui/core/Container";
import LoginTheme from "./LoginTheme";
import { Form, Field } from "react-final-form";
import SessionFetches from "../../../Requests/Session/Session";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN_SUCCESS } from "../../../Redux/Actions/Types/Session/Session";
function Copyright() {
  return (
    <Typography variant="body2" style={{ color: "white" }} align="center">
      Version 1.0 - Designed and Developed by Looplabs Australia
    </Typography>
  );
}

export default function SignIn() {
  const classes = LoginStyles();
  const renderTF = (fieldName, title, placeholder) => {
    return (
      <Grid item xs={12}>
        <div className="add-new-job-field-title">
          <p>{title}</p>
        </div>
        <Field name={fieldName}>
          {({ input }) => (
            <input
              placeholder={placeholder}
              type={fieldName}
              {...input}
              className="login-textfield-input"
            />
          )}
        </Field>
      </Grid>
    );
  };

  const dispatch = useDispatch();

   const handleLoginRequest = formObj => {
     const loginRequest = SessionFetches.requestLogin(formObj);
     loginRequest.then(response => {
       if (response) {
         if (response.access_token) {
           if (formObj.remember_me) {
             localStorage.setItem("supersecretkey", response.access_token);
           } else {
             sessionStorage.setItem("supersecretkey", response.access_token);
           }
           dispatch({
             type: LOGIN_SUCCESS
           });
         } else if (response.message === "No Autorizado") {
         } else {
         }
       }
     });
   };

  // const handleLoginRequest = formObj => {
  //   if (formObj.email === "test@nomail.com" && formObj.password === "123123")
  //     dispatch({
  //       type: LOGIN_SUCCESS
  //     });
  // };

  useEffect(() => {
    if (
      localStorage.getItem("supersecretkey") ||
      sessionStorage.getItem("supersecretkey")
    ) {
      dispatch({
        type: LOGIN_SUCCESS
      });
    }
  }, [dispatch]);

  const logged = useSelector(state => state.session.logged);
  return (
    <MuiThemeProvider theme={LoginTheme}>
      <div className={classes.container}>
        {logged && <Redirect to="/" />}
        <Container component="main" maxWidth={"sm"}>
          <CssBaseline />
          <div className={classes.paper}>
            <div>
              <img
                src="/images/get-directed-logo.png"
                alt=""
                className="login-img-wrapper"
              />
            </div>
            <div style={{ padding: " 8px 0" }}>
              <Form
                onSubmit={formObj => {
                  //   dispatch({type: SESSION_REQUEST,
                  //     payload: SessionFetches.requestLogin(formObj)
                  // })}
                  handleLoginRequest(formObj);
                }}
              >
                {({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={4}>
                      {renderTF("email", "", "E-mail or Account Name")}
                      {renderTF("password", "", "Password")}
                      <Grid item xs={12}>
                        <div className="login-rem-for-wrapper">
                          <div className="login-rem-item-wrapper">
                            <label className="login-rem-item-label-wrapper">
                              <Field
                                name="remember_me"
                                component="input"
                                type="checkbox"
                                defaultValue={false}
                                className="login-remember-me"
                              />
                              Remember me
                            </label>
                          </div>
                          <div className="login-for-item-wrapper">
                            <p>Forgot password?</p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="login-submit-button-wrapper">
                          <Button className={classes.submit} type="submit">
                            LOGIN
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Form>
            </div>
          </div>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Container>
      </div>
    </MuiThemeProvider>
  );
}
