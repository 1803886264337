import React from "react";
import { Grid } from "@material-ui/core";
import NewJob from "../../../../UI/Elements/Cards/NewJob/NewJob";
import JobDetails from "../../../../UI/Elements/Cards/JobDetails/JobDetails";

const GridView = ({ jobs, completed, openStaffModal, setOpenStaffModal }) => {
  return (
    <Grid container spacing={2} style={{ width: "calc(100% + 8px)" }}>
      {!completed && (
        <Grid item xs={4}>
          <NewJob />
        </Grid>
      )}
      {jobs &&
        jobs.map((job, index) => {
          return (
            <Grid item xs={4} key={index}>
              <JobDetails data={job} completed={completed}  openStaffModal={openStaffModal} setOpenStaffModal={setOpenStaffModal} />
            </Grid>
          );
        })}
    </Grid>
  );
};

export default GridView;
